import GATSBY_COMPILED_MDX from "/opt/buildhome/repo/content/sections/guides/integrate/boarding/index.mdx";
import React from "react";
import PageMetadata from "../page/page-metadata";
import DocumentationLayout from "./documentation-layout";
import {HeadingOne} from "../common/typography";
import {MDXProvider} from "../mdx/mdx-provider";
export const SectionMDXRenderer = _ref => {
  let {data, location, children} = _ref;
  return React.createElement("div", {
    className: "mx-6 md:mx-12 xl:mx-20"
  }, React.createElement(PageMetadata, {
    title: data.section.title
  }), React.createElement(DocumentationLayout, {
    path: location.pathname
  }, React.createElement(HeadingOne, {
    unanchored: true
  }, data.section.title), React.createElement(MDXProvider, null, children)));
};
const query = "2483838310";
SectionMDXRenderer
export default function GatsbyMDXWrapper(props) {
  return React.createElement(SectionMDXRenderer, props, React.createElement(GATSBY_COMPILED_MDX, props));
}
